import React, { useEffect, useRef } from "react";
import video_mp4 from "../assets/img/jibun_no_yakyu_PV.mp4";

export function BackGroundVideo() {
  const videoRef = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, [videoRef]);
  const videoStyle = {
    width: "100%", // 画面幅いっぱいに広げる
    height: "auto", // アスペクト比を保つ
    objectFit: "cover", // サイズを覆うようにする
    objectPosition: "center", // 中央に位置するようにする
  } as React.CSSProperties;
  return (
    <React.StrictMode>
      {/*<video style={videoStyle} muted loop ref={videoRef} autoPlay playsInline>*/}
      <video style={videoStyle} muted loop ref={videoRef} autoPlay playsInline>
        {/*<source src="../assets/img/jibun_no_yakyu_PV.mp4" type="video/mp4" />*/}
        <source
          src="https://khk-homepage-bucket.s3.ap-northeast-1.amazonaws.com/jibun_no_yakyu_PV.mp4"
          type="video/mp4"
        />
        <p>Your browser doesn't support HTML5 video.</p>
      </video>
    </React.StrictMode>
  );
}
