import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg2 from "../assets/img/F4DfKEeaEAEg9up.jpg";
import projImg1 from "../assets/img/VRChat_2023-07-12_22-04-43.525_2160x3840.png";
import yakyuImg1 from "../assets/img/yakyu.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import "animate.css";
import React from "react";

export const Projects = () => {
  const cast = [
    {
      title: "Girl's Voice Bar Castrato",
      description: "毎週水曜22時～24時",
      imgUrl: projImg1,
      url: "https://twitter.com/Bar_CastratoVR",
    },
    {
      title: "ラバーカフェ Café Lattex",
      description: "隔週月曜22時～23時",
      imgUrl: projImg2,
      url: "https://twitter.com/Cafelattex_vrc",
    },
  ];

  const event = [
    {
      title: "VRC野球盤・野球大会",
      description: "毎週土曜22時～",
      imgUrl: yakyuImg1,
      url: "https://twitter.com/VRC_Baseball",
    },
  ];

  return (
    <section className="project" id="cast">
      <Container>
        <Row>
          <Col size={12}>
            <div className={"animate__animated animate__fadeIn"}>
              <h2>Cast & Events</h2>
              <p>
                私KenhokuがVRChatで活動しているイベント・団体の一覧です。
                <br />
                Twitter（X）のアカウントがある場合はリンクを貼っています。
              </p>
              <Tab.Container id="projects-tabs" defaultActiveKey="first">
                <Nav
                  variant="pills"
                  className="nav-pills mb-5 justify-content-center align-items-center"
                  id="pills-tab"
                >
                  <Nav.Item>
                    <Nav.Link eventKey="first">所属イベント</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second">活動中の団体</Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content
                  id="slideInUp"
                  className={"animate__animated animate__slideInUp"}
                >
                  <Tab.Pane eventKey="first">
                    <Row>
                      {cast.map((project, index) => {
                        return (
                          <ProjectCard
                            key={index}
                            title={project.title}
                            description={project.description}
                            imgUrl={project?.imgUrl}
                            url={project?.url}
                          />
                        );
                      })}
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <Row>
                      {event.map((project, index) => {
                        return (
                          <ProjectCard
                            key={index}
                            title={project.title}
                            description={project.description}
                            imgUrl={project.imgUrl}
                            url={project?.url}
                          />
                        );
                      })}
                    </Row>
                  </Tab.Pane>
                  {/*<Tab.Pane eventKey="third">*/}
                  {/*  <p>*/}
                  {/*    Lorem ipsum dolor sit amet consectetur adipisicing elit.*/}
                  {/*    Cumque quam, quod neque provident velit, rem explicabo*/}
                  {/*    excepturi id illo molestiae blanditiis, eligendi dicta*/}
                  {/*    officiis asperiores delectus quasi inventore debitis quo.*/}
                  {/*  </p>*/}
                  {/*</Tab.Pane>*/}
                </Tab.Content>
              </Tab.Container>
            </div>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  );
};
