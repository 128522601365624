import React, { SetStateAction, useEffect, useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import logo from "../assets/img/logo-design2.png";
import { BrowserRouter as Router } from "react-router-dom";
import { FaXTwitter } from "react-icons/fa6";

export const NavBar = () => {
  const [activeLink, setActiveLink] = useState("home");
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onUpdateActiveLink = (value: SetStateAction<string>) => {
    setActiveLink(value);
  };

  return (
    <Router>
      <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
        <Container>
          <Navbar.Brand>
            <img
              style={{
                width: "150px",
                paddingTop: "25px",
                paddingBottom: "5px",
              }}
              src={logo}
              alt="Logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link
                href="#top"
                className={
                  activeLink === "top" ? "active navbar-link" : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("home")}
              >
                Top
              </Nav.Link>
              <Nav.Link
                href="#cast"
                className={
                  activeLink === "cast" ? "active navbar-link" : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("skills")}
              >
                Cast & Events
              </Nav.Link>
              <Nav.Link
                href="#about"
                className={
                  activeLink === "about" ? "active navbar-link" : "navbar-link"
                }
                onClick={() => onUpdateActiveLink("home")}
              >
                About me
              </Nav.Link>
              {/*<Nav.Link*/}
              {/*  href="#projects"*/}
              {/*  className={*/}
              {/*    activeLink === "projects"*/}
              {/*      ? "active navbar-link"*/}
              {/*      : "navbar-link"*/}
              {/*  }*/}
              {/*  onClick={() => onUpdateActiveLink("projects")}*/}
              {/*>*/}
              {/*  Announcement*/}
              {/*</Nav.Link>*/}
            </Nav>
            {/* 以下のメニューは小さい画面では表示しない*/}
            <span className="navbar-text">
              <Nav className="d-none d-md-flex">
                <div className="social-icon">
                  <a
                    href="https://twitter.com/Kenhoku_VRC"
                    target={"_blank"}
                    rel={"noopener noreferrer"}
                  >
                    <FaXTwitter color={"white"} />
                  </a>
                </div>
              </Nav>

              <Nav className="d-none d-md-flex">
                <a
                  href="https://vrchat.com/home/user/usr_27181952-1641-4821-a714-8b9ed47cbf8a"
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                >
                  <button className="vvd">
                    <span>Would you be my friend?</span>
                  </button>
                </a>
              </Nav>
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Router>
  );
};
