import React from "react";
import { Col } from "react-bootstrap";

interface ProjectCardProps {
  imgUrl: string;
  title: string;
  description: string;
  url?: string;
}

export const ProjectCard: React.FC<ProjectCardProps> = ({
  imgUrl,
  title,
  description,
  url,
}) => {
  return (
    <Col xs={12} sm={6} md={4}>
      <div className="proj-imgbx">
        <img src={imgUrl} alt={title} />
        <div className="proj-txtx">
          <a
            style={{
              color: "white",
              textDecoration: "none",
            }}
            href={url}
            target={"_blank"}
            rel={"noopener noreferrer"}
          >
            <h4>{title}</h4>
            <span>{description}</span>
          </a>
        </div>
      </div>
    </Col>
  );
};
