import { Col, Container, Row } from "react-bootstrap";
import logo from "../assets/img/logo-design2.png";
import React from "react";
import { FaXTwitter } from "react-icons/fa6";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Col size={2} xs={8} sm={10}>
            <img
              style={{
                width: "150px",
                paddingTop: "25px",
                paddingBottom: "5px",
              }}
              src={logo}
              alt="Logo"
            />
          </Col>
          <Col size={2} xs={4} sm={2} className="text-center text-sm-end">
            <div className="social-icon">
              <a
                href="https://twitter.com/Kenhoku_VRC"
                target={"_blank"}
                rel={"noopener noreferrer"}
              >
                <FaXTwitter color={"white"} />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
