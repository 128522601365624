import React, { useState, useEffect } from "react";
import "../App.css";
import img from "../assets/img/logo-design.png";

const LoadingAnimation = () => {
  const [loading, setLoading] = useState(true);
  const isMobile = window.innerWidth <= 767;
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    // スマホ表示のときはsectionを表示しない
    <section className={isMobile ? "project" : ""} id="top">
      <div className={loading ? "loading" : "loaded"}>
        <img src={img} alt="loading" />
      </div>
    </section>
  );
};

export default LoadingAnimation;
