import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import headerImg from "../assets/img/VRChat_2023-07-18_01-08-23.440_3840x2160.png";
import { ArrowRightCircle } from "react-bootstrap-icons";
import "animate.css";

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState("");
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = ["Web Developer", "Web Designer", "Team Leader"];
  const period = 2000;
  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting
      ? fullText.substring(0, text.length - 1)
      : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta((prevDelta) => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex((prevIndex) => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === "") {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex((prevIndex) => prevIndex + 1);
    }
  };

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => {
      clearInterval(ticker);
    };
  }, [text]);

  return (
    <section className="banner" id="about">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={8}>
            <div className={"animate__animated animate__fadeIn"}>
              <span className="tagline">VRC User Kenhoku's homepage</span>
              <h1>
                {`VRCユーザー Kenhokuのホームページ`} {/*<span*/}
                {/*  className="txt-rotate"*/}
                {/*  data-period={1000}*/}
                {/*  data-rotate='[ "Web Developer", "Web Designer", "Team Leder" ]'*/}
                {/*>*/}
                {/*  <span className="wrap">{text}</span>*/}
                {/*</span>*/}
              </h1>
              <p>Kenhokuの暇つぶしホームページ</p>
              <p>
                気が向いたときにキャストの活動予定を更新したりする怠惰なホームページだよ
              </p>
              <a
                style={{
                  textDecoration: "none",
                }}
                href="https://vrchat.com/home/user/usr_27181952-1641-4821-a714-8b9ed47cbf8a"
                target={"_blank"}
                rel={"noopener noreferrer"}
              >
                <button className={"vvd"}>
                  <div
                    style={{
                      color: "#333333",
                    }}
                  >
                    Would you be my friend?
                  </div>
                  <ArrowRightCircle color={"black"} size={25} />
                </button>
              </a>
            </div>
          </Col>
          <Col xs={12} md={6} xl={4}>
            {/*<div className={"animate__fadeIn"}>*/}
            <img src={headerImg} alt="Header Img" />
            {/*</div>*/}
          </Col>
        </Row>
      </Container>
    </section>
  );
};
